// ApiContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  // Initialize activeMenu from localStorage or default to "dashboard"
  const [activeMenu, setActiveMenu] = useState(() => {
    return localStorage.getItem("activeMenu") || "dashboard";
  });

  const apiMethod = async (itemName) => {
    // Implement your API logic here
  };

  // Function to update the active menu
  const updateActiveMenu = (menu) => {
    setActiveMenu(menu);
    localStorage.setItem("activeMenu", menu); // Save to localStorage
  };

  // Optional: Clear activeMenu on logout or other actions
  const clearActiveMenu = () => {
    setActiveMenu("dashboard");
    localStorage.removeItem("activeMenu");
  };

  return (
    <ApiContext.Provider
      value={{ apiMethod, activeMenu, updateActiveMenu, clearActiveMenu }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};

export default ApiContext;
