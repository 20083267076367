// LocalizationContext.js
import React, { createContext, useState, useContext } from "react";

const LocalizationContext = createContext();

export const LocalizationProvider = ({ children }) => {
  const [locale, setLocale] = useState("ar"); // Default to English

  const setLanguage = (lang) => {
    setLocale(lang);
  };

  return (
    <LocalizationContext.Provider value={{ locale, setLanguage }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
