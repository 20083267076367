import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const translations = {
  en: {
    heading: "404 - Page Not Found",
    message: "Oops! The page you're looking for doesn't exist.",
    buttonText: "Go Back to Home",
  },
  ar: {
    heading: "404 - الصفحة غير موجودة",
    message: "عذرًا! الصفحة التي تبحث عنها غير موجودة.",
    buttonText: "العودة إلى الصفحة الرئيسية",
  },
};

export default function NotFound() {
  const [locale, setLocale] = useState("en");
  const navigate = useNavigate();

  const handleToggleLocale = () => {
    setLocale((prevLocale) => (prevLocale === "en" ? "ar" : "en"));
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        margin: "0",
        padding: "0",
        textAlign: "center",
        height: "100vh", // Full viewport height
        background: "linear-gradient(135deg, #6e45e2, #88d3ce)",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        {translations[locale].heading}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontSize: "1.5rem",
          marginBottom: "2rem",
          maxWidth: "600px",
        }}
      >
        {translations[locale].message}
      </Typography>

      <Button
        variant="contained"
        onClick={handleGoHome}
        sx={{
          backgroundColor: "#fff",
          color: "#6e45e2",
          padding: "0.75rem 2rem",
          fontSize: "1.2rem",
          borderRadius: "2rem",
          ":hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        {translations[locale].buttonText}
      </Button>

      <Button
        onClick={handleToggleLocale}
        sx={{
          marginTop: "1.5rem",
          color: "#fff",
          fontSize: "1rem",
          textDecoration: "underline",
        }}
      >
        {locale === "en" ? "عرض باللغة العربية" : "View in English"}
      </Button>
    </Box>
  );
}
