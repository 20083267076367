// TabsContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const TabsContext = createContext();

export const TabsProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem("activeTab");
    return savedTab !== null ? parseInt(savedTab, 10) : 0; // Default to 0 if no value is saved
  });

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab); // Save active tab to local storage
  }, [activeTab]);

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </TabsContext.Provider>
  );
};

export const useTabs = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabs must be used within a TabsProvider");
  }
  return context;
};

export default TabsContext;
