import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear stored login details
    localStorage.removeItem("username");
    localStorage.removeItem("password");

    // Redirect to login page
    navigate("/");
  }, [navigate]);

  return null; // No need to render anything for logout
}
